import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyB1p68tSqYdbT6mj3mpIsyWI7kfeIA5-nE",
  authDomain: "netnews-5243f.firebaseapp.com",
  databaseURL: "https://netnews-5243f-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "netnews-5243f",
  storageBucket: "netnews-5243f.appspot.com",
  messagingSenderId: "1085079507732",
  appId: "1:1085079507732:web:b197aea67f75b7048b2801",
  measurementId: "G-HFHRM6PKFC"
};

const app = initializeApp(firebaseConfig);
const authentication = getAuth();

export {app, authentication};
