import React, { useState, useEffect, useRef } from "react";
import { FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import BreadcrumbNav from "./BreadcrumbNav";
import { deleteusernotificationApi } from "../store/actions/campaign";
import { translate } from "../utils";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import no_image from "../images/images.jpeg";
import { loaduserNotification } from "../store/reducers/notificationbadgeReducer";

function Notification() {
    const [Data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const initialData = useRef([]);

    useEffect(() => {
      initialData.current = Data;
    }, [Data]);



    useEffect(() => {
        loaduserNotification(
            "0",
            "10",
            (response) => {
                setData(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "No Data Found") {
                    setData("");
                }
            }
        );
    }, []);


    const handleDeleteComment = (e, id) => {
        e.preventDefault()
        deleteusernotificationApi(
            id,
            (response) => {
                 // Remove the deleted notification from the state
                setData(prevData => prevData.filter(notification => notification.id !== id));
                toast.success(response.message)
                setIsLoading(false);
                loaduserNotification("0","10",()=>{},()=>{})
            },
            (error) => {
                setIsLoading(false);
                if (error === "No Data Found") {
                    setData("");
                }
            }

        );
    };

    return (
        <>
            <BreadcrumbNav SecondElement="Notification" ThirdElement="0"/>

            <div className="personal_Sec py-5 bg-white">
                <div id="main-Noticard" className="container ">
                    <div className="d-flex bd-highlight mb-3">
                        <Link to="/persnol-notification" id="btnNotification1" className="btn mx-1 bd-highlight">
                            {" "}
                            {translate("personalLbl")}{" "}
                        </Link>
                        <Link to="/news-notification" id="btnNewsnoti" className="btn mx-1 bd-highlight">
                            {" "}
                            {translate("news")}
                        </Link>
                        {/* <button id='btnNotification1' className="btn  btn mx-1 ms-auto bd-highlight" onClick={handleDeleteAll} > Delete All</button> */}
                    </div>
                    <div className="my-3">
                        {isLoading ? (
                            <div className="col-12 loading_data">
                                <Skeleton height={20} count={22} />
                            </div>
                        ) : Data.length > 0 ? (
                            Data.map((element,index) => (
                                <div className="card my-3" key={index}>
                                    <div className="card-body bd-highlight" id="card-noti">
                                    <img id="noti_profile" src={element.profile ? element.profile : no_image} alt="" />
                                        <div className="Noti-text">
                                            <p className="bd-highlight bd-title">{translate("replay-comment")}  {element.message}</p>
                                            <p>{translate("by")} {element.name}</p>
                                            <p className="bd-highlight"> {element.date} {translate("ago")}</p>
                                        </div>

                                        <div className="iconTrash ms-auto bd-highlight">
                                            <button className="btn  btn m-2 " id="btntrash" onClick={(e) => handleDeleteComment(e,element.id)}>
                                                <p className="hide-mobile mb-0">{translate("deleteTxt")}</p>
                                                <p className="hide-laptop">
                                                    <FiTrash2 />
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-12 no_data mt-5">
                                <p className="text-center">{translate("nodatafound")}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </>
    );
}

export default Notification;
