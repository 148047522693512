import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { gettagsApi } from "../store/actions/campaign";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { translate } from "../utils";

function TagsSection() {
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        gettagsApi(
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [currentLanguage]);

    return (
        <div>
            <div id="rns-tags-main" className="my-3">
                <nav id="tags-nav" className="navbar">
                    <h4 id="rns-nav-logo" className="mb-0">
                        <b>{translate("tagLbl")}</b>
                    </h4>
                </nav>
                <div id="tags-tag">
                    {Data &&
                        Data.map((element) => (
                            <Link id="btnTags" key={element.id} to={"/tag-news-view?Tid=" + element.id} className="btn btn-outline-dark">
                                {element.tag_name}
                            </Link>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default TagsSection;
